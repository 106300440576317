.main {
  z-index: 100000;
  bottom: 0;
  background: linear-gradient(
    180deg,
    #b4e9d9 5%,
    #52b4bb 25%,
    #52b4bb 30%,
    #3658e0 100%
  );
  background-size: cover;
  transition: opacity 0.5s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.container_sticky {
  position: relative;
  backdrop-filter: blur(2px);
  color: white;
}

.logo_banner_flextival {
  width: 50%;
}

#btn_schedule {
  z-index: 1;
  background-color: #142f95;
  border-radius: 50px;
  border-color: #142f95;
  color: white;
}

.close_banner_flextival {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  margin: 1rem;
}

@media (max-width: 768px) {
  #schedule {
    display: none !important;
    font-size: 0.8rem;
  }
  #btn_schedule {
    font-size: 1rem;
  }
  .logo_banner_flextival {
    width: 50%;
    margin-bottom: 2%;
  }
  .title_banner_schedule > p {
    font-size: 0.8rem;
    line-height: 0.2rem;
  }
  .close_banner_flextival {
    font-size: 1.5rem;
    margin: 0.5rem;
  }
}
